import React from "react";
import axios from "axios"; // For making client request.


class Form extends React.Component {
  constructor(props){
    super(props);
    this.state = {name: "", surname: "", email: "", message: ""};
  }

 

  render() {
    return (
      <form   action="https://formspree.io/f/xwkjlqdo"    method="POST">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input
              className="form-control"
              id="name"
              name="name"
              type="text"
              placeholder="Your Name *"
              required="required"
              data-validation-required-message="Please enter your name."
            />
            <p className="help-block text-danger" />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              name="email"
              id="email"
              type="email"
              placeholder="Your Email *"
              required="required"
              data-validation-required-message="Please enter your email address."
            />
            <p className="help-block text-danger" />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              id="phone"
              name="phone"
              type="tel"
              placeholder="Your Phone *"
              required="required"
              data-validation-required-message="Please enter your phone number."
            />
            <p className="help-block text-danger" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <textarea
              className="form-control"
              id="message"
              name="message"
              placeholder="Your Message *"
              required="required"
              data-validation-required-message="Please enter a message."
            />
            <p className="help-block text-danger" />
          </div>
        </div>
        <div className="clearfix" />
        <div className="col-lg-12 text-center">
          <div id="success" />
          <button
            id="sendMessageButton"
            className="btn btn-primary btn-xl text-uppercase"
            type="submit"
          >
            Send Message
          </button>
        </div>
      </div>
    </form>
    );
  }
}

export default Form;