import React from "react";
import Form from "./Form";


export default function Contact() {
  return (
    // Contact
    <section className="page-section" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Contact Us</h2>
            <h3 className="section-subheading text-muted">
              We respond to all emails within 3 hours. 
            </h3>
          </div>
        </div>

        <div class="row">
  <div class="col-lg-8 mb-4">
    <iframe  margin-left="auto" margin-right="auto" height="400px" frameborder="0" scrolling="no" alt='Bergen County House Cleaning'  src="img/nj.png"></iframe>
  </div>
  <div id ="text" className="col-lg-4 mb4">
    <h3>Montclair Maids travels all over New Jersey & NYC so you dont have too!</h3>

    <h6>
      Get answers to your questions from our Customer Happiness Team. Call, email, text or leave a message below.
    </h6>

    <p>
      <abbr title="Phone">Phone</abbr>: (973)-446-5611  
       </p>
    <p>
      <abbr title="Email">Email</abbr>:
      <a href="mailto:contact@montclairmaids.com"> contact@montclairmaids.com
      </a>
    </p>
    <p>
      <abbr title="Hours">Hours</abbr>: Monday - Sunday: 6:00 AM to 8:00 PM
    </p>
  </div>
</div>


        <div className="row">
          <div className="col-lg-12">
<Form /> 
          </div>
        </div>
      </div>
    </section>
  );
}
