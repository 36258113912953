import React from "react";

export default function Footer() {
  return (
    // Footer
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <span className="copyright">Copyright &copy; Montclair Maids 2023</span>
          </div>
          <div className="col-md-4">
            <ul className="list-inline social-buttons">
              <li className="list-inline-item">
                <a
                  href="https://www.instagram.com/montclairmaids/"
                  target="blank"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.facebook.com/montclairmaids/" target="blank">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              
            </ul>
          </div>
          <div className="col-md-4">
            Servicing Bergen Passaic County, Husdson County, Essex County, Union County, Morris County and NYC
          </div>

          
      
        </div>
      </div>
    </footer>
  );
}
