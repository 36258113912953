import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      //about section
      <section className="page-section" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Testimonials</h2>
              <h3 className="section-subheading text-muted">
                Read why our customers love us. 100% Satisfaction Guaranteed
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="timeline">
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/1.jpg"
                      alt="carpet cleaning"
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Feb 2023</h4>
                      <h4 className="subheading">T. Delluca</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                      Awesome service, very reliable and they actually clean. Not a piece of dust was left and other places I have used have left dust all over my tables but they did a fantastic job! definitely recommend!!

                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/2.jpg"
                      alt="apartment cleaning"
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Feb 2023</h4>
                      <h4 className="subheading">A.Ten Kate</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                      I’m amazed by this company. The staff is bilingual which is so helpful and they were super friendly. The cleaning services are outstanding for such an affordable price. I will definitely keep using this business.

                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/3.jpg"
                      alt="house cleaning"
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Jan 2023</h4>
                      <h4 className="subheading">K. Soto</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                      Montclair Maids has definitely satisfied my needs! Because I have bad allergies, I was very shocked by the products the staff uses. When I got home, I was free of the constant watery eyes & sneezing. Also, my dog & cat were not affected by the products used in my home because the products are eco friendly & animanl cruelty free. The company exceeded my expectations & I highly recommend them to anyone who is in need of a home refresher!

                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/4.jpg"
                      alt="NJ cleaning company"
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Dec 2022</h4>
                      <h4 className="subheading">A. Coss</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                      Montclair Maids is hands down the best cleaning company in Essex county. I have used a ton of cleaning services, but none leave my house as clean as Montclair Maids. It’s great that you can book and recieve quotes online. My house was left spotless and smelling fabulous! They are quick, trustworthy, and dependable. I highly recommend this company.

                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <h4>
                      Be Part
                      <br />
                      Of Our
                      <br />
                      Story!
                    </h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
